(function() {
    // missing forEach on NodeList for IE11
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }
    
    document.addEventListener('DOMContentLoaded', function () {
        footerLogo = document.querySelectorAll('img[src="../../images/logo-dark.svg"]')[0];
        if (footerLogo) {
            footerLogo.setAttribute('alt', 'footer logo');
        }

        placeholder = document.querySelectorAll('img[src="/wp-content/themes/jupiter-child/img/products.png"]');
        placeholder.forEach(function (image, index) {
            image.setAttribute('alt', 'placeholder image ' + (index + 1));
        });
    });
})();
