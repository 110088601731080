/* ========================================================================
 * Jupiter Child Theme Customizations
 *
 * Just a bunch of JavaScript that customized theme behavior.
 * ======================================================================== */

/**
 * Returns a URL Parameter from a URL.
 * If no URL is provided, the current URL will be used.
 *
 * @returns {*}
 * @param param
 * @param url
 */
function getUrlParam(param, url) {
    url = url === 'undefined' ? window.location.href : url;
    var results = new RegExp('[\?&]' + param + '=([^&#]*)').exec(url);
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
}

/**
 * Document ready.
 */
(function ($) {

    /**
     * Will replace YouTube embeds that do not contain a rel=0 with
     * a new DOM node that does.
     */
    (function youtubeEmbeds() {
        $('iframe[src*="youtube.com"]').each(function () {
            var src = $(this).attr('src');
            if (getUrlParam('rel', src) === null) {
                var replacement = $(this).clone();
                $(replacement).attr('src', src + '&rel=0');
                $(this).replaceWith(replacement);
            }
        });
    })();

    //
    // Emit a 'gravity_form_submission' event and 'gf_form_id' to the dataLayer
    // For tracking form submissions with GTM and Google Analytics
    // __using jQuery is necessary for compatibility with GravityForms__
    jQuery(document).bind('gform_confirmation_loaded', function (event, formId) {
        window.dataLayer.push({
            'event': 'gravity_form_submission',
            'gf_form_id': formId
        });
    });

})(jQuery);


document.addEventListener('DOMContentLoaded', function () {

    // query all of the image tabs we want to hide on mobile
    var tabGroupLevelOneChildren = document.querySelectorAll('ul.ubermenu-nav ul.ubermenu-submenu ul.ubermenu-tabs-group ul.ubermenu-tab-content-panel .ubermenu-item');
    for (i = 0; i < tabGroupLevelOneChildren.length; i++) {
        // Add a class do we can target these with css media queries
        tabGroupLevelOneChildren[i].parentElement.classList.add('cotterman-special-ul');
        // Add a class to the tabs parent anchor el so we can assign an href below
        var el = tabGroupLevelOneChildren[i].parentElement.previousSibling;
        if (el != null) {
            el.classList.add('cotterman-special-a');
        }
    }

    // query all the anchor elements we assigned a class to above
    var cottermanSpecialA = jQuery('.cotterman-special-a');

    var detectTap;
    var ubermenu = jQuery('.ubermenu-nav');
    ubermenu.on('mousedown touchstart', function () {
        detectTap = true; //detects all touch events
        // console.log('touch start');
    });
    ubermenu.on('mousemove touchmove', function () {
        detectTap = false; //Excludes the scroll events from touch events
        // console.log('touch move');
    });
    cottermanSpecialA.on('mouseup touchend touchcancel ubermenuopen', function (e) {
        console.log('touch end');
        if (e.type == "click" || e.type == "ubermenuopen") detectTap = true; //detects click events
        if (detectTap && jQuery(this).attr('path')) {
            window.location = jQuery(this).attr('path');
        }
    });
});